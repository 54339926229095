<template>
    <section>
        <div class="switchBox" :class="{ 'is-disabled': switchDisabled, 'is-checked': checked }" role="switch" :aria-checked="checked" :aria-disabled="switchDisabled" @click.prevent="switchValue">
            <input class="switch__input" type="checkbox" @change="handleChange" ref="input" :true-value="activeValue" :false-value="inactiveValue" :disabled="switchDisabled" @keydown.enter="switchValue">
            <span class="switch__core" ref="core" style="width: 40px"></span>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'switchBtn',
        props: {
            value: {
                type: [Boolean, String, Number],
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            width: {
                type: Number,
                default: 40
            },
            activeColor: {
                type: String,
                default: ''
            },
            inactiveColor: {
                type: String,
                default: ''
            },
            activeValue: {
                type: [Boolean, String, Number],
                default: true
            },
            inactiveValue: {
                type: [Boolean, String, Number],
                default: false
            },
        },
        data () {
            return {
            }
        },
        created() {
            if (!~[this.activeValue, this.inactiveValue].indexOf(this.value)) {
                this.$emit('input', this.inactiveValue);
            }
        },
        computed: {
            checked() {
                return this.value === this.activeValue;
            },
            switchDisabled() {
                return this.disabled;
            }
        },
        watch: {
            checked() {
                this.$refs.input.checked = this.checked;
                if (this.activeColor || this.inactiveColor) {
                    this.setBackgroundColor();
                }
            }
        },
        methods: {
            handleChange() {
                const val = this.checked ? this.inactiveValue : this.activeValue;
                this.$emit('input', val);
                this.$emit('change', val);
                this.$nextTick(() => {
                    this.$refs.input.checked = this.checked;
                });
            },
            setBackgroundColor() {
                let newColor = this.checked ? this.activeColor : this.inactiveColor;
                this.$refs.core.style.borderColor = newColor;
                this.$refs.core.style.backgroundColor = newColor;
            },
            switchValue() {
                !this.switchDisabled && this.handleChange();
            },
        },
        mounted() {
            if (this.activeColor || this.inactiveColor) {
                this.setBackgroundColor();
            }
            this.$refs.input.checked = this.checked;
        }
    }
</script>
<style lang="scss" scoped>
    .switchBox {
        display: inline-flex;
        align-items: center;
        position: relative;
        font-size: 14px;
        line-height: 20px;
        height: 20px;
        vertical-align: middle;
        .switch__input {
            position: absolute;
            width: 0;
            height: 0;
            opacity: 0;
            margin: 0;
            cursor: pointer;
        }
        .switch__core {
            margin: 0;
            display: inline-block;
            position: relative;
            width: 40px;
            height: 20px;
            border: 1px solid #dcdfe6;
            outline: none;
            border-radius: 10px;
            box-sizing: border-box;
            background: #dcdfe6;
            cursor: pointer;
            transition: border-color .3s,background-color .3s;
            vertical-align: middle;
            &::after {
                content: "";
                position: absolute;
                top: 1px;
                left: 1px;
                border-radius: 100%;
                transition: all .3s;
                width: 16px;
                height: 16px;
                background-color: #fff;
            }
        }
    }
    .switchBox.is-checked .switch__core {
        border-color: #409eff;
        background-color: #409eff;
        &::after {
            left: 100%;
            margin-left: -17px;
        }
    }
</style>
